<template>
    <div>
        <!--begin::customer-->
        <b-card no-body class="mb-5">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter') }}
                </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="customer_id">{{$t('payments_sales_report.customer')}}</label>
                                <!-- <select name="" id="customer_id" v-model="filters.customer_id" class="custom-select">
                                    <option v-for="row in customers" :value="row.id" :key="'customer'+row.id">
                                        {{ row.fullname }}
                                    </option>
                                </select> -->
                                <multiselect v-model="customer"
                                             :placeholder="$t('payments_sales_report.customer')"
                                             label="fullname"
                                             track-by="id"
                                             :options="customers"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getCustomers($event)">
                                </multiselect>
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="from_date">{{$t('payments_sales_report.from_date')}}</label>
                                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="to_date">{{$t('payments_sales_report.to_date')}}</label>
                                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('payments_sales_report.added_by')}}</label>
                                <!-- <select name="" id="added_by" v-model="filters.user_id" class="custom-select">
                                    <option v-for="row in users" :value="row.id" :key="'by'+row.id">
                                        {{ row.name }}
                                    </option>
                                </select> -->
                                <multiselect v-model="user"
                                             :placeholder="$t('payments_sales_report.added_by')"
                                             label="name"
                                             track-by="id"
                                             :options="users"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getUsers($event)">
                                </multiselect>
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('payments_sales_report.payment_method')}}</label>
                                <select name="" id="payment_method" v-model="filters.payment_method" class="custom-select">
                                    <option v-for="row in payment_method_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('payments_sales_report.collected_by')}}</label>
                                <!-- <select name="" id="collected_user_id" v-model="filters.collected_user_id" class="custom-select">
                                    <option v-for="row in users" :value="row.id" :key="row.id">
                                        {{ row.name }}
                                    </option>
                                </select> -->
                                <multiselect v-model="collected_by"
                                             :placeholder="$t('payments_sales_report.collected_by')"
                                             label="name"
                                             track-by="id"
                                             :options="users"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getUsers($event)">
                                </multiselect>
                            </div>

                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <div class="card card-custom">
            <div class="card-body">
                <b-tabs content-class="mt-3">
                    <b-tab :title="$t('summery')" :active="tab == 'summery' || tab == null">
                        <div class="row mb-10">
                            <div class="col-md-12 mt-10" id="summaryTableTitle">
                                <h4 class="text-center">{{ $t('payments_sales_report.payments_sales_report')}}</h4>
                                <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from')}} {{filters.from_date}} {{ $t('to')}} {{filters.to_date}}</h5>
                            </div>
                            <div class="col-md-12 mt-10" v-if="line_invoice_details">
                                <BarChart :data-list="line_invoice_details" :currency-name="currency_name"/>
                            </div>
                        </div>
                        <div class="row mb-10">
                            <div class="col-12">
                                <div class="table-responsive">
                                    <div class="row justify-content-end p-4">
                                        <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                            <download-excel
                                                    class="dropdown-item"
                                                    :fetch="salesInvoiceExport"
                                                    :fields="json_fields"
                                                    :name="$t('payments_sales_report.payments_sales_report')+'.xls'">
                                                <i class="la la-file-excel"></i>{{$t('excel')}}
                                            </download-excel>
                                            <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                                                <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                            </button>
                                            <button class="dropdown-item" @click="printData('summaryTable', 'pdf')">
                                                <span><i class="fa fa-file-pdf"></i> <span>{{$t('export_pdf')}}</span></span>
                                            </button>
                                        </b-dropdown>
                                    </div>
                                    <table id="summaryTable" class="table table-bordered">
                                        <thead>
                                        <tr>

                                            <th>{{$t('payments_sales_report.'+periodLabel)}}</th>
                                            <th>{{$t('payments_sales_report.total')}} ({{currency_name}})</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(row, index) in data_report_summary" :key="'dataReport'+index">
                                            <td>{{row.label}}</td>
                                            <td>{{row.total}}</td>
                                        </tr>

                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td>{{$t('payments_sales_report.total')}}</td>
                                            <th>{{summary_total_sum}}</th>
                                        </tr>
                                        </tfoot>
                                    </table>


                                </div>

                            </div>
                        </div>
                    </b-tab>

                    <b-tab :title="$t('details')" :active="tab == 'details'">
                        <div class="mt-3">
                            <div class="row mb-10">
                                <div class="col-md-12 mt-10" id="DetailsTableTitle">
                                    <h4 class="text-center">{{ $t('payments_sales_report.payments_sales_report')}}</h4>
                                    <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from')}} {{filters.from_date}} {{ $t('to')}} {{filters.to_date}}</h5>
                                </div>
                                <div class="col-md-12 mt-10" v-if="line_invoice_details">
                                    <BarChart :data-list="line_invoice_details" :currency-name="currency_name"/>
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-12">
                                    <div class="table-responsive">
                                        <div class="row justify-content-end p-4">
                                            <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                                <download-excel
                                                        class="dropdown-item"
                                                        :fetch="salesInvoiceDetailsExport"
                                                        :fields="json_fields_details"
                                                        :name="$t('payments_sales_report.payments_sales_report')+'.xls'">
                                                    <i class="la la-file-excel"></i>{{$t('excel')}}
                                                </download-excel>
                                                <button class="dropdown-item" @click="printData('DetailsTable', 'print')">
                                                    <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                                </button>
                                              <button class="dropdown-item" @click="printData('DetailsTable', 'pdf')">
                                                <span><i class="fa fa-file-pdf"></i> <span>{{$t('export_pdf')}}</span></span>
                                              </button>
                                            </b-dropdown>
                                        </div>
                                        <table class="table table-bordered" id="DetailsTable">
                                            <thead>
                                            <tr>
                                                <th>{{$t('payments_sales_report.number')}}</th>
                                                <th>{{$t('payments_sales_report.invoice_number')}}</th>
                                                <th>{{$t('payments_sales_report.customer')}}</th>
                                                <th>{{$t('payments_sales_report.employee')}}</th>
                                                <th>{{$t('payments_sales_report.payment_method')}}</th>
                                                <th>{{$t('payments_sales_report.ref_no')}}</th>
                                                <th>{{$t('payments_sales_report.total')}} ({{currency_name}})</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <template v-for="(row, index) in data_report_details">
                                                <tr :key="'a'+index">
                                                    <td colspan="7">{{row.label}}</td>
                                                </tr>
                                                <tr v-for="(row2, index2) in row.details" :key="'a'+getRandom()+index2">
                                                    <td>{{row2.id}}</td>
                                                    <td>{{row2.invoice_code}}</td>
                                                    <td>{{row2.customer_name}}</td>
                                                    <td>{{row2.added_by}}</td>
                                                    <td>{{row2.payment_method_name}}</td>
                                                    <td>{{row2.ref_no}}</td>
                                                    <td>{{row2.total}}</td>
                                                </tr>
                                                <tr :key="'b'+index">
                                                    <td colspan="6">{{$t('payments_sales_report.total')}}</td>
                                                    <td>{{row.total.sum_total}}</td>
                                                </tr>
                                            </template>

                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td colspan="6"><b>{{$t('payments_sales_report.total')}}</b></td>
                                                <th>{{details_total_sum}}</th>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <template #tabs-end>
                        <li class="nav-item  d-flex my-auto">
                            <select name="" id="" v-model="filters.filter_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none">
                                <option :value="null">{{$t('select_filter')}}</option>
                                <option value="daily">{{$t('daily')}}</option>
                                <option value="weekly">{{$t('weekly')}}</option>
                                <option value="monthly">{{$t('monthly')}}</option>
                                <option value="yearly">{{$t('yearly')}}</option>
                                <option value="customer">{{$t('customer')}}</option>
                                <option value="employee">{{$t('employee')}}</option>
                                <option value="payment_method">{{$t('payment_method')}}</option>
                            </select>

                        </li>
                    </template>
                    <!-- <b-tab> -->
                    <!-- </b-tab> -->
                </b-tabs>
                <div class="col-12 text-center" v-if="page">
                    <button class="btn btn-warning" @click="loadMore">
                        <p class="mb-0">
                            <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                            {{$t('load_more')}}
                        </p>
                    </button>
                </div>
            </div>
        </div>
      <div class="selector-export" id="selectorExport"></div>
        <!--end::customer-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import BarChart from './components/BarChart.vue';
    import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";

    export default {
        name: "index-sales-by-client-report",
        components: {BarChart},
        data() {
            return {
                mainRoute: '/reports/sales',
                mainRouteDependency: 'base/dependency',

                currency_name: null,

                line_invoice_details: [],

                data_report_details: [],
                details_total_sum: null,

                data_report_summary: [],
                summary_total_sum: null,


                filters: {
                    customer_id: null,
                    from_date: null,
                    to_date: null,
                    user_id: null,
                    payment_method: null,
                    collected_user_id: null,
                    filter_by: null,
                },

                data: [],
                customers: [],
                users: [],
                payment_method_list: [],
                isPeriod: false,
                periodLabel: 'label',
                customer: null,
                user: null,
                collected_by: null,
                tab: this.$route.query.tab ? this.$route.query.tab : null,
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],
                page: 0,
            }
        },
        watch: {
            "filters.filter_by": function (val) {
                if (val)
                    this.doFilter();
                switch (val) {
                    case 'daily':
                        this.isPeriod = true;
                        this.periodLabel = 'day';
                        break;
                    case 'weekly':
                        this.isPeriod = true;
                        this.periodLabel = 'week';
                        break;
                    case 'monthly':
                        this.isPeriod = true;
                        this.periodLabel = 'month';
                        break;
                    case 'yearly':
                        this.isPeriod = true;
                        this.periodLabel = 'year';
                        break;
                    case 'customer':
                        this.isPeriod = true;
                        this.periodLabel = 'customer';
                        break;
                    case 'employee':
                        this.isPeriod = true;
                        this.periodLabel = 'employee';
                        break;
                    case 'payment_method':
                        this.isPeriod = true;
                        this.periodLabel = 'payment_method';
                        break;

                }
            },
            customer: function (val) {
                if (val) {
                    this.filters.customer_id = val.id;
                } else {
                    this.filters.customer_id = null;
                }
            },
            user: function (val) {
                if (val) {
                    this.filters.user_id = val.id;
                } else {
                    this.filters.user_id = null;
                }
            },
            collected_by: function (val) {
                if (val) {
                    this.filters.collected_user_id = val.id;
                } else {
                    this.filters.collected_user_id = null;
                }
            },
        },
        computed: {

            json_fields: function () {
                let fields = {};

                fields[this.$t('payments_sales_report.' + this.periodLabel)] = 'label';
                fields[this.$t('payments_sales_report.total') + ` (${this.currency_name})`] = 'total';

                return fields;
            },

            json_fields_details: function () {
                let fields = {};
                fields[this.$t('payments_sales_report.id')] = 'label';
                fields[this.$t('payments_sales_report.invoice_number')] = 'invoice_code';
                fields[this.$t('payments_sales_report.date')] = 'payment_date';
                fields[this.$t('payments_sales_report.customer')] = 'customer_name';
                fields[this.$t('payments_sales_report.employee')] = 'added_by';
                fields[this.$t('payments_sales_report.payment_method')] = 'payment_method_name';
                fields[this.$t('payments_sales_report.ref_no')] = 'ref_no';
                fields[this.$t('revenue_invoices.total') + ` (${this.currency_name})`] = 'total';

                return fields;
            },


        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_reports"), route: '/reports/sales-reports-links'}, {title: this.$t("MENU.payments_sales_report")}]);

            this.filters.filter_by = this.$route.query.filter ? this.$route.query.filter : null;

            this.getCustomers();
            this.getUsers();
            this.getPaymentMethodList();
            this.getAuthUser();
        },
        methods: {

            salesInvoiceExport() {
                this.setReportLog('excel', 'payments sales report');
                let _footer = {label: this.$t('payments_sales_report.total') + ` (${this.currency_name})`, total: this.summary_total_sum};
                let _data = [...this.data_report_summary];
                _data.push(_footer);
                return _data;

            },
            salesInvoiceDetailsExport() {
                let _footer = {label: this.$t('payments_sales_report.total') + ` (${this.currency_name})`, total: this.details_total_sum};
                let _data = [];
                this.data_report_details.forEach((row) => {
                    _data.push({
                        label: row.label,
                        invoice_code: '',
                        payment_date: '',
                        customer_name: '',
                        added_by: '',
                        payment_method_name: '',
                        ref_no: '',
                        total: '',
                    });
                    row.details.forEach((sub_row) => {
                        _data.push({
                            label: sub_row.id,
                            invoice_code: sub_row.invoice_code,
                            payment_date: sub_row.payment_date,
                            customer_name: sub_row.customer_name,
                            added_by: sub_row.added_by,
                            payment_method_name: sub_row.payment_method_name,
                            ref_no: sub_row.ref_no,
                            total: sub_row.total,
                        });
                    });
                    _data.push({
                        label: this.$t('payments_sales_report.total') + ` (${this.currency_name})`,
                        invoice_code: '',
                        payment_date: '',
                        customer_name: '',
                        added_by: '',
                        payment_method_name: '',
                        ref_no: '',
                        total: row.total.sum_total,
                    });
                });
                _data.push(_footer);

                return _data;
            },

            getRandom() {
                return Math.floor(Math.random() * 10000);
            },

            doFilter() {
                this.page = 1;
                this.getLineChartInvoiceDetails();
                this.getReportInvoiceDetails();
                this.getReportInvoiceSummary();

            },
            resetFilter() {
                this.filters.customer_id = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.user_id = null;
                this.filters.payment_method = null;
                this.filters.collected_user_id = null;
                this.filters.filter_by = null;
                this.customer = null;
                this.user = null;
                this.collected_by = null;
            },
            async getCustomers(filter) {
                if(filter && filter.length >= 3)
                    await ApiService.get(this.mainRouteDependency + "/customers", {params:{filter: filter}}).then((response) => {
                        this.customers = response.data.data;
                    });
            },
            getUsers(filter) {
                if(filter && filter.length >= 3)
                    ApiService.get(this.mainRouteDependency + "/users", {params:{filter: filter}}).then((response) => {
                        this.users = response.data.data;
                    });
            },
            getAuthUser() {
                ApiService.get(`/get_auth`).then((response) => {
                    this.currency_name = response.data.data.currency_name;
                });
            },
            getEmployees() {
                ApiService.get(this.mainRouteDependency + "/employees").then((response) => {
                    this.employees = response.data.data;
                });
            },
            getPaymentMethodList() {
                ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
                    this.payment_method_list = response.data.data;
                });
            },

            loadMore() {
                this.page = this.page ? (this.page + 1) : 1;
                this.getLineChartInvoiceDetails();
                this.getReportInvoiceDetails();
                this.getReportInvoiceSummary();
            },
            /*****
             * details
             */
            getLineChartInvoiceDetails() {
                ApiService.get(`${this.mainRoute}/client_payment_chart`, {params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => {
                    this.line_invoice_details = response.data;
                });
            },

            getReportInvoiceDetails() {
                ApiService.get(`${this.mainRoute}/client_payment_details`, {params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => {
                    this.data_report_details = response.data.data;
                    this.details_total_sum = response.data.total.sum_total_total;
                });
            },
            /**
             * get for summary tab
             */
            getReportInvoiceSummary() {
                ApiService.get(`${this.mainRoute}/client_payment_details`, {params: {...this.filters, page: (this.page ? this.page : 1), data_type: 'list'}}).then((response) => {
                    this.data_report_summary = response.data.data;
                    this.summary_total_sum = response.data.total.sum_total_total;
                });
            },
            printData(tableId, type) {
                this.setReportLog('pdf', 'payments sales report');

                // let divToPrint = document.getElementById(tableId);
                // let divTitleToPrint = document.getElementById(tableId + 'Title');
                // let newWin = window.open("");
                // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                // setTimeout(() => {
                //     newWin.print();
                //     newWin.close();
                // }, 100)
              exportPDFInnerAction(tableId , type, this.$t('payments_sales_report.payments_sales_report'), this.$t('payments_sales_report.payments_sales_report'));
            },
            /**
             * register log
             */
            setReportLog(key, description) {
                ApiService.post("reports/system_activity_log/log_type", {
                    key: key,
                    description: description,
                });
            }

        },
    };
</script>
<style>
.html2pdf__overlay{
  opacity: 0 !important;
  z-index: -1000;

}
.selector-export{
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}
</style>